@tailwind base;
@tailwind components;
@tailwind utilities;


/* Assuming you have a CSS file for your component or global styles */

/* Targeting the toolbar header (usually .ql-toolbar) */
.my-quill-editor .ql-toolbar {
    background-color: #1f2937;
    color: white;
    border-color: var(--border-color) !important;
  }
  

  .my-quill-editor .ql-container {
    border-color: var(--border-color) !important; /* Ensure no border around the container */
}

  /* Targeting the editor body (usually .ql-editor) */
  .my-quill-editor .ql-editor {
    background-color: var(--bg-color);
     color: var(--text-color); /* Adjust text color if needed */
    border: 1px solid #ccc; /* Example: Add border to editor */
    padding: 10px; /* Example: Add padding to editor */
    border-color: var(--border-color) !important;
  }
  
  .ql-toolbar.ql-snow{
     border-color: var(--border-color) !important; border: none !important;
  }

/* Targeting Quill editor classes and applying white color */
.my-quill-editor .ql-toolbar .ql-picker-label,
.my-quill-editor .ql-toolbar .ql-picker-options li,
.my-quill-editor .ql-toolbar .ql-stroke {
    color: white !important;
}


/* Targeting .ql-stroke within .ql-snow theme */
.ql-snow .ql-stroke {
    fill: none;
    stroke: #fff  !important;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
}



.rcp {
  width: 70%;
}



/* styles.css */
.bg-vscode-dark {
  background-color: #1e1e1e;
}

.text-dark {
  color: #d4d4d4;
}

.vscode {
  background-color: #1e1e1e;
  color: #d4d4d4;
  border: 1px solid #333;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
}



.text-green-800 {
  color: #2f855a; /* Adjust color code to your preference */
}

.text-blue-500 {
  color: #3182ce; /* Adjust color code to your preference */
}



.image-list-container {
  height: 150px; /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ccc; /* Optional: Add a border to distinguish the container */
  padding: 10px; /* Optional: Add padding for better appearance */
}



.scrollable-container {
  overflow: auto;
  height: calc(100vh - 4rem); /* Adjust based on your layout */
}

/* Webkit Browsers (Chrome, Safari) */
.scrollable-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar */
}

/* Firefox */
.scrollable-container {
  scrollbar-width: none; /* Hide scrollbar */
}

/* Internet Explorer/Edge */
.scrollable-container {
  -ms-overflow-style: none; /* Hide scrollbar */
}


:root {
  --bg-color: #f9f9f9; /* Light mode background */
  --bg-color1: #e5e7eb;
  --bg-color2: #e5e7eb;
  --text-color: #333;   /* Light mode text */
  --border-color: #ddd; /* Light mode border */
  --table-header: #e5e7eb;
  --input-form: #ffffff;
  --dropdown: #ffff;
  --navbar: #ffff;
}

.dark-mode {
  --bg-color: #1a222c; /* Dark mode background */
  --bg-color1: #1a222c;
  --bg-color2: #24303f;
  --text-color: #ffffff; /* Dark mode text */
  --border-color: #444; /* Dark mode border */
  --table-header: #111827;
  --input-form:#1d2a39;
  --dropdown: #24303f;
  --navbar: #1f2937;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
}

.border {
  border-color: var(--border-color);
}

.bg-white {
  background-color: var(--bg-color); /* Adjust for dark mode */
}

.bg-gray-300{
  background-color: var(--bg-color1); 
}

.bg-gray-200{
  background-color: var(--bg-color1); 
}

.text-gray-700 {
  color: var(--text-color); /* Adjust for dark mode */
}

.bg-gray-100{
  background-color: var(--table-header); 
}


.border-b{
  border-color: var(--border-color);
}


.bg-slate-50{
  background-color: var(--input-form);
}


.bg-gray-400{
  background-color: var(--bg-color1); /*used bg-color2 before*/
}

.bg-card{
  background-color: var(--bg-color2);
}



.bg-dropdown{
  background-color: var(--dropdown);
}


.bg-navbar{
  background-color: var(--navbar);
}



/* Webkit browsers (Chrome, Safari) */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
